<template>
  <div class="flex flex-col flex-1 overflow-y-hidden">

    <PLoader v-if="isLoading" />

    <div class="flex flex-col flex-1 overflow-y-hidden lg:flex-row lg:space-x-4" v-else>
    
      <div class="flex-wrap overflow-y-auto bg-white border border-gray-300 rounded flex-0 lg:flex-no-wrap lg:w-72">

          <div class="p-4 text-xs font-medium leading-none text-white rounded-t bg-gradient-to-b from-gray-500 to-gray-600">
            Leieforhold nr.: {{ rental.id }}
          </div>

          <div class="p-4">
            <div class="flex lg:flex-col lg:space-y-4 space-x-4 lg:space-x-0">              
              <PText label="Status">
                <PRentalStatus 
                  class="text-sm" 
                  :status="rental.rentalStatus" 
                />
              </PText>              
              <PText label="Container ID">{{ rental.containerId || "-" }}</PText>
              <PText label="Container type">{{ rental.containerTypeName || "-" }}</PText>
              <PText label="Innhold">{{ rental.containerContent || "-" }}</PText>
              <PText label="Nåværende lokasjon">{{ rental.locationName }}</PText>
            </div>

            <a href
              class="flex items-center mt-8 text-sm text-red-500 underline space-x-1"              
              v-if="canDelete"
              @click.prevent="onDelete"
              color="danger"
            >
              <FontAwesomeIcon :icon="['far', 'trash-alt']" />
              <div>Slett bestilling</div>
            </a>
          
          </div>

      </div>
      
      <div class="flex-1 mt-4 overflow-y-scroll bg-white border border-gray-300 rounded lg:mt-0">
        <POrderGridTable                
          :cols="cols"
          :filters="filters"
          :orders="orders"        
          :totalCount="orders.length"
          :loading="isLoading"
          :expandable="false"
          :expandAll="false"
          :showFooter="false"          
          @click="onClick"      
        >
        </POrderGridTable>
      </div>
    
    </div>

    <ContainerOrderDialog
      v-if="containerOrderDialogVisible"
      @close="onCloseContainerOrderDialog" 
      :containerRentalId="id"      
      :containerOrderId="selectedContainerOrderId"
    />

    <portal to="actions">
      <PButton 
        @click="containerOrderDialogVisible = true"      
        :disabled="!canOrder"   
        color="primary"
      >
        Bestill tjeneste
      </PButton>
    </portal>

  </div>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import {
  get,
  forEach, 
  every 
} from "lodash"
import POrderGridTable from "@/components/ordergrid/POrderGridTable"
import ContainerOrderDialog from "../dialogs/ContainerOrderDialog"

export default {
  
  props: {
    id: {
      type: String,
      required: true
    }
  },

  components: {
    POrderGridTable,
    ContainerOrderDialog
  },

  data () {
    return {
      isLoading: false,
      rental: null,
      orders: [],
      containerOrderDialogVisible: false,
      selectedContainerOrderId: "",
      cols: [        
        { text: "Tjeneste", value: "containerOrderTypeName", component: "p-display-text", align: "left" },        
        { text: "Leveringsdato", value: "deliveryDate", component: "p-display-date", width: 140, align: "center" },        
        // { 
        //   nested: [            
        //     { text: "Hendelse", value: "transactionTypeName", component: "p-display-text", align: "left", width: 220 },
        //     { text: "Dato", value: "date", component: "p-display-date", align: "left", width: 140 },
        //     { text: "Beløp", value: "amount", component: "p-display-price", width: 80, align: "right" },
        //   ] 
        // },
        { text: "Referanse", value: "customerReference", component: "p-display-text", align: "left" },        
        { text: "Kommentar", value: "customerComment", component: "p-display-text", align: "left" },        
        { text: "Siste aktivitet", value: "current", component: "p-display-container-order-current", align: "left" },        
        { text: "Status", value: "status", component: "p-display-container-order-status", align: "center", width: 140 },        
      ],      
      filters: {
      //   sendLower: { value: "" },
      //   sendUpper: { value: "" },
      },   
      orderBy: {
        field: "dateArrived",
        descending: true
      },
    }
  },  

  computed: {
       
    ...mapGetters([
      "customer", 
      "location"
    ]),
    
    args () {

      return {
        locationId: this.location.id,
        filters: this.filters
      }
    },

    rentalId () {
      return get(this.rental, "containerId", null)
    },

    canOrder () {            
       return this.rentalId !== null
    },
    
    canDelete () {
      return every(this.orders, order => order.status === "Registered")      
    }

  },

  methods: {

    onClick (containerOrder) {
      this.selectedContainerOrderId = containerOrder.id
      this.containerOrderDialogVisible = true
    },

    async onDelete () {
      
      try {
        
        let promises = []

        forEach(this.orders, order => {
          promises.push(http.delete(`ContainerOrder?id=${order.id}`))
        })

        await Promise.all(promises)

        this.$notifySuccess("Slette bestilling", "Bestillingen ble slettet.")

        this.$router.replace({ name: "containers.Rentals" })

      } catch (error) {
        this.$notifyError(error.reason)
      }

    },

    async onCloseContainerOrderDialog () {
      this.containerOrderDialogVisible = false
      this.selectedContainerOrderId = ""
      await this.getOrders()
    },
    
    async getOrders () {

        this.isLoading = true

        try {
          
          const [
            rental,
            orders 
          ] = await Promise.all([
            http.get(`ContainerRental?id=${this.id}`),
            http.get(`ContainerOrders?containerRentalId=${this.id}&withTransactions=true`),
          ])

          this.rental = rental
          this.orders = orders

        } catch (error) {          
          this.$notifyError("", error.reason)
        }

        this.isLoading = false
    }
  },


  watch: {
    args: {
      handler: async function () {
        await this.getOrders ()
      }, immediate: true
    }
  }

}
</script>
